

















































import AppVue from "./case";
export default AppVue;
