
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import Axios, { AxiosResponse } from "axios";
import { BaseComponent } from "../base/baseComponent";
import { HttpHelper } from '../../helper/httpHelper';

@Component({})
export default class MainVue extends BaseComponent {


    /** 当前的技术特点索引值 */
    curCharacteristicIndex: number = 0;

    characteristicList: Array<any> = [
        { name: '开发框架', desc: '使用灵活的开发框架<br />支持微信、支付宝、抖音小程序的快速开发' },
        { name: '游戏引擎', desc: 'laya\cocos高性能游戏引擎，提升游戏的流畅性和体验感' },
        { name: '技术架构', desc: '基于自主研发的Pi开发者平台技术架构，自动化集成<br/>微信/支付宝/今日头条等多个平台API，支持H5跨平台运营' },
        { name: '跨多端', desc: '使用React Native和Uniapp跨平台开发框架<br/>一套前端代码可同时发布ios和安卓端<br/>缩短开发周期，降低前端代码维护的复杂性' },
        { name: '自适应', desc: '自适应网站，兼容PC/移动端<br/>保证网站在PC端和移动端的适配性和数据同步' },
        { name: '控制中台', desc: '配套后端控制系统，简易操作，灵活扩展<br/>可根据不同时期的内容和数据需求增加功能模块' },
        { name: '运 营', desc: '根据推广需求整合运营资源，为项目上线保驾护航' }
    ];

    waitAutoChangeTime: number = 0;

    clientList: Array<any> = [];

    mounted() {

        HttpHelper.requestData(HttpHelper.urls.getClientList, (code: number, msg: string, data: any) => {
            this.clientList = data;
        });

        new (<any>window).WOW().init();

        setInterval(() => {

            if (this.waitAutoChangeTime > 0) {
                this.waitAutoChangeTime--;
                return;
            }
 
            if (this.curCharacteristicIndex >= this.characteristicList.length) {
                this.curCharacteristicIndex = 0;
            }
            else {
                this.curCharacteristicIndex = this.curCharacteristicIndex + 1;
            }
        }, 2000);
    }

    onClickCharacteristicIndex(index: number) {
        this.curCharacteristicIndex = index;
        this.waitAutoChangeTime = 1 ;
    }
}