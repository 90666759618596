
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import Axios, { AxiosResponse } from "axios";
import { BaseComponent } from "../base/baseComponent";
import { HttpHelper } from "@/helper/httpHelper";

@Component({})
export default class CaseVue extends BaseComponent {

    curTabIndex: number = 0;

    tabList: Array<any> = [];

    caseList: Array<any> = [];

    mounted() {

        HttpHelper.requestData(HttpHelper.urls.getTypeList, (code: number, msg: string, data: any) => {
            this.tabList = data;
            this.onClickTab(0);
        });

    }

    onClickTab(index: number) {
        this.curTabIndex = index;
        HttpHelper.requestData(HttpHelper.urls.getCaseList, (code: number, msg: string, data: any) => {
            this.caseList = data;
            this.$nextTick(() => {
                new (<any>window).WOW().init();
            })
            new (<any>window).WOW().init();
        }, {}, {
            type: this.tabList[index].Id,
            fromIndex: 0,
            count: 999,
        });
        new (<any>window).WOW().init();
    }

}