import { Component, Prop, Provide, Vue } from "vue-property-decorator";

export abstract class BaseComponent extends Vue {

    config: any;

    constructor(config: any) { 
        super();
        this.config = config;
    }



}