import Axios, { AxiosResponse } from 'axios';
/** http请求 */
export class HttpHelper {

    static reqHost:string = "https://as.paimon.cn"; //本地开发
    // static reqHost:string = "http://127.0.0.1:6500"; //本地开发

// 
    //获得url
    static getUrl(partUrl:string){
        return this.reqHost + partUrl ;   
    } 

    static urls:any = { 
        getTypeList:"/OfficialWebServer/GetTypeList.ashx", // 获得案例类型列表 
        getClientList:"/OfficialWebServer/GetClientList.ashx", // 获得客户列表 
        getCaseList:"/OfficialWebServer/GetCaseList.ashx", // 获得案例列表 
    };


    //请求数据
    static requestData(url: string, callback: Function, getParams ? : any, postParams ? : any) {
        if (!url) {
            return;
        }
        url = this.getUrl(url) ;
        var firstParam: boolean = true;
        getParams = getParams || {};
        getParams["t"] = new Date().getTime(); //加一个时间戳

        if (getParams) {
            if (url.indexOf('?') > -1) {
                //说明之前已经拼接了参数
                firstParam = false;
            }
            for (var p in getParams) {
                url += firstParam ? '?' : '&';
                firstParam = false;
                url += p + '=' + getParams[p];
            }
        }
        var xmlhttp: any;
        if (window.XMLHttpRequest) { // IE7+, Firefox, Chrome, Opera, Safari 代码
            xmlhttp = new XMLHttpRequest();
        } else { // IE6, IE5 代码
            xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
                if (callback) {
                    var data = JSON.parse(xmlhttp.responseText);
                    if (data.code == 200) { //返回结果正确
                        callback(data.code, data.msg, data.data, data.count);
                    }
                    else {
                        callback(data.code, data.msg, data.data, data.count);
                    }
                }
            }
        };
        xmlhttp.open("POST", url, true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        //将userId和token填进去   
        postParams = postParams || {};   
        firstParam = true;
        var postData = "";
        for (var p in postParams) {
            if (!firstParam) {
                postData += "&";
            }
            firstParam = false;
            postData += p + "=" + encodeURIComponent(postParams[p]);
        }
        xmlhttp.send(postData);
    }
}