import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router'; 
import RouterConfig from './config';

Vue.use(VueRouter); 
const routes: RouteConfig[] =  RouterConfig.alls ;     
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// console.log(RouterConfig.getAllPage()); 
export default router;
