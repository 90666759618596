import MainVue from "@/views/main/Main.vue";
import CaseVue from '../views/case/Case.vue';

 



/** 路由配置 */
export default class RouterConfig {

  static defaultTitle: string = '派蒙科技';
  static defaultDesc: string = '派蒙科技';
  static defaultKeywords: string = '派蒙科技';

  static getAllPage(): Array<string> {
    var list: Array<string> = [];
    this.alls.forEach((item: any) => {
      list.push(item.path);
    });
    return list;
  }

  static alls: Array<any> = [
    {
      path: '/',
      name: 'main',
      component: MainVue,
    },
    {
      path: '/case',
      name: 'case',
      component: CaseVue,
    },
  ]  
}